import React from 'react'
import Products from './products/Products'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import './content.css'
import { getProducts } from '../requests';
import {useSelector, useDispatch} from 'react-redux'
import { getProductsSelector, productSelector, setProductToView, getLoading } from '../slices/SlicesStore';
import userEvent from '@testing-library/user-event'
// import Banner from '../header/Banner'
import Helmet from 'react-helmet'
function Catalog({exFilter}) {
  const dispatch = useDispatch()
  const productToSelect = useSelector(productSelector.getProductToSelect)

  const data = useSelector(getProductsSelector.getProducts)

  return(
    <div className='catalog-container'>
          <Helmet>
          <title>Agent KEI | Каталог</title>
          <meta name="twitter:title" content="Agent KEI | Каталог"/>
          <meta name="twitter:description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров."/>
          <meta property="og:title" content="Agent KEI | Каталог"/>
          <meta property="og:description" name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров." />
          <meta name="description" content="Магазин техники «Agent KEI». У нас вы можете приобрести продукцию таких брендов, как Apple и Samsung. Мы гарантируем высокое качество и подлинность всех товаров." />
        </Helmet>
      <div className="catalog">
        <Products data={data} />
      </div>
    </div>

  )
}
export default Catalog
